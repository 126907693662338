<template>
    <section class="calendar">
        <div
            v-if="
                selectedDay && typeOfCalendar && meetingsForPrint.length !== 0
            "
            id="print"
            class="calendar__print-container"
        >
            <CalendarPrintPage
                :date="selectedDay"
                :type-of-meetings="typeOfCalendar"
                :get-meeting-per-day="meetingsForPrint"
            />
        </div>
        <CalendarModalAdd
            :fields-data="requestData.forClient"
            :is-active-data="modals.add.isActive"
            :meeting-managers="usersByRole"
            @close-handle="closeAdd"
            @on-add="addHandle"
        />
        <CalendarModalUpdate
            :is-active-data="modals.update.isActive"
            :meeting="selectedMeeting"
            :type-of-meetings="typeOfCalendar"
            :meeting-managers="usersByRole"
            @close-handle="closeUpdate"
        />
        <SubHeader
            :title="textContent['title']"
            :crumbs="textContent['crumbs']"
        />
        <div class="calendar__body">
            <div class="calendar__actions">
                <div class="calendar__actions-container">
                    <template v-if="isMobile">
                        <v-select
                            v-model="typeOfCalendar"
                            color="#967adc"
                            dense
                            label="Calendar"
                            :items="buttons"
                            :disabled="isListType"
                            outlined
                        ></v-select>
                    </template>
                    <template v-else>
                        <v-btn
                            v-for="({ text, value, color }, i) in buttons"
                            :key="i"
                            :color="color"
                            small
                            :light="isListType"
                            :dark="!isListType"
                            :disabled="isListType"
                            max-width="18%"
                            :outlined="value === typeOfCalendar"
                            @click="() => onSwitch(value)"
                        >
                            {{ isMobileText ? cutterForBtnText(text) : text }}
                        </v-btn>
                    </template>
                    <v-btn
                        v-if="!isMobile"
                        min-width="50"
                        :light="isListType"
                        :dark="!isListType"
                        :disabled="isListType"
                        color="#967adc"
                        small
                        outlined
                        @click="printHandle"
                    >
                        <v-icon small>fa-print</v-icon>
                    </v-btn>
                </div>
                <CalendarManagerSelector
                    v-if="typeOfCalendar === 'pick_up_new'"
                    :type-of-calendar="typeOfCalendar"
                    :users-by-role="usersByRole"
                    @managers-handler="managersHandler"
                />
            </div>

            <div class="calendar__main">
                <CalendarMain
                    :start-data="start"
                    :meetings="preparedMeetings"
                    :type-of-meetings="typeOfCalendar"
                    :is-mobile="isMobile"
                    :type-of-format="typeOfFormat"
                    :selected-managers="selectedManagers"
                    @create-meeting="onCreate"
                    @update-handle="openUpdate"
                    @select-day="selectDayHandle"
                    @change-type="changeTypeFormatHandle"
                />
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import CalendarMain from '@/components/calendar/calendar-main'
import CalendarModalAdd from '@/components/modals/calendar-modal-add'
import CalendarModalUpdate from '@/components/modals/calendar-modal-update'
import CalendarPrintPage from '@/components/modals/calendar-print-page'
import CalendarManagerSelector from '../components/calendar/calendar-manager-selector.vue'

import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes,
    getterTypes as getterTypesCalendar,
    getterTypes,
} from '@/store/modules/calendar'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import {
    getterTypes as getterTypesModals,
    actionTypes as actionTypesModals,
} from '@/store/modules/modals'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import {
    actionTypes as actionTypesUsers,
    getterTypes as gettersTypesUsers,
} from '@/store/modules/users'

import popUpTypes from '@/types/pop-up-types'
import { getInMilliseconds, getRange } from '@/helpers/getDateInFormat'
import languageMixin from '@/mixins/language.mixin'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'
import serializeMeetingData, {
    serializeHolidaysData,
} from '@/helpers/calendar-serializer.utils'
import { getterTypesJewishCalendar } from '@/store/modules/jewish-calendar/types'

const { calendarContext } = popUpTypes

export default {
    name: 'Calendar',
    components: {
        SubHeader,
        CalendarMain,
        CalendarModalAdd,
        CalendarModalUpdate,
        CalendarPrintPage,
        CalendarManagerSelector,
    },
    mixins: [languageMixin],
    data() {
        return {
            typeOfFormat: 'month',
            windowWidth: window.innerWidth,
            start: null,
            transitData: null,
            typeOfCalendar: 'orders',
            selectedDay: '',
            isBusy: false,
            modals: {
                add: {
                    isActive: false,
                },
                update: {
                    isActive: false,
                },
            },
            selectedMeeting: {},
            requestData: {
                forClient: {
                    fields: {
                        subject: '',
                        client_id: null,
                        date: '',
                        time: '',
                        calendar: '',
                        wig_id: null,
                        client: '',
                        manager_id: null,
                    },
                    errors: {},
                },
            },
            selectedManagers: [],
        }
    },
    computed: {
        ...mapGetters('jewishCalendar', {
            getJewishDates: getterTypesJewishCalendar.getJewishDates,
        }),
        ...mapGetters('calendar', {
            orders: getterTypes.getAllForOrders,
            pickUp: getterTypes.getAllForPickUpNew,
            repairs: getterTypes.getAllForRepairs,
            pickUpRepairs: getterTypes.getAllForPickUpRepairs,
            usAgent: getterTypes.getAllForUsAgent,
            getMeetingPerDay: getterTypesCalendar.getMeetingsPerDay,
            getAllMeetings: getterTypesCalendar.getAllMeetings,
        }),
        ...mapGetters('modals', {
            checkIsActive: getterTypesModals.isActive,
            payload: getterTypesModals.payload,
        }),
        ...mapGetters('users', {
            usersByRole: gettersTypesUsers.getUsersByRole,
        }),
        buttons: function () {
            const isUsaAgent = this.getAllowed(['usa_manager'])
            const types = [
                {
                    text: this.textContent['orders'],
                    value: 'orders',
                    color: '#967adc',
                },
                {
                    text: this.textContent['pick-up-new'],
                    value: 'pick_up_new',
                    color: '#37bc9b',
                },
                {
                    text: this.textContent['repairs'],
                    value: 'repairs',
                    color: '#f6bb42',
                },
                {
                    text: this.textContent['pick-up-repairs'],
                    value: 'pick_up_repairs',
                    color: '#f6bb42',
                },
                {
                    text: this.textContent['us-agent'],
                    value: 'us_agent',
                    color: '#b05454',
                },
            ]
            return isUsaAgent
                ? types.filter(({ value }) => value === 'us_agent')
                : types
        },
        isActive: function () {
            return this.checkIsActive(calendarContext)
        },
        extraData: function () {
            return this.payload(calendarContext)
        },
        isMobile: function () {
            return this.windowWidth < 425
        },
        isMobileText: function () {
            return this.windowWidth < 1024
        },
        completeDate: function () {
            return `${this.requestData.forClient.fields.date} ${this.requestData.forClient.fields.time}`
        },
        meetings: function () {
            //TODO: clear after approve
            // switch (this.typeOfCalendar) {
            //     case 'orders':
            //         return this.orders
            //     case 'pick_up_new':
            //         return this.pickUp
            //     case 'repairs':
            //         return this.repairs
            //     case 'pick_up_repairs':
            //         return this.pickUpRepairs
            //     case 'us_agent':
            //         return this.usAgent
            //     default:
            //         return []
            // }

            return this.getAllMeetings
        },
        preparedMeetings: function () {
            const lang = this.isRtl ? 'he' : 'en'

            return [
                ...serializeMeetingData(this.meetings),
                ...serializeHolidaysData(this.getJewishDates(lang)),
            ]
        },
        meetingsForPrint: function () {
            const allMeetingsPerDay = this.getMeetingPerDay(
                this.typeOfCalendar,
                this.selectedDay
            )

            if (!this.checkIsManager()) {
                return allMeetingsPerDay
            }

            const selectedManagerIds = this.selectedManagers.map(
                (manager) => manager.id
            )

            const filteredMeetings = allMeetingsPerDay.filter((meeting) => {
                return selectedManagerIds.includes(meeting.meeting_manager_id)
            })

            return filteredMeetings
        },
        isListType: function () {
            return this.typeOfFormat === 'list'
        },
    },
    watch: {
        isActive: function (value) {
            if (value) {
                this.setState()
            }
        },
        buttons: function () {
            this.typeOfCalendar = this.buttons[0].value
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
        this.setState()
        this.typeOfCalendar = this.buttons[0].value
        this.loadUsersByRole('MANAGER_PLUS')
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions('calendar', {
            getMeetings: actionTypes.getAllForSelected,
            onAdd: actionTypes.addNewOne,
            setMeetingsType: actionTypes.setMeetingsType,
            setMeetingsRange: actionTypes.setMeetingsRange,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
            error: actionTypesModalNotifications.error,
        }),
        ...mapActions('orders', {
            updateCompleteDate: actionTypesOrders.updateCompleteDate,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        ...mapActions('users', {
            loadUsersByRole: actionTypesUsers.loadUsersByRole,
        }),
        ...mapActions('ordersV2', {
            loadAllV2: ordersV2ActionTypes.FETCH_ORDERS,
        }),
        setState: function () {
            const action = this.extraData?.action
            if (!action) return null
            switch (action) {
                case 'for-client':
                    this.requestData.forClient.fields.client_id =
                        this.extraData?.clientId
                    break
                case 'for-wig':
                    this.requestData.forClient.fields.wig_id =
                        this.extraData.wigId
                    this.requestData.forClient.fields.client_id =
                        this.extraData.clientId
                    this.requestData.forClient.fields.manager_id =
                        this.extraData?.meetingManagerId || null
                    this.typeOfCalendar = this.extraData.type
                    break
                case 'to-date':
                    this.typeOfCalendar = this.extraData.type || 'orders'
                    this.typeOfFormat = 'day'
                    this.start = new Date(
                        getInMilliseconds(this.extraData.selectDate)
                    )

                    this.setMeetingsRange(getRange(this.start))

                    break
                default:
                    return
            }
        },
        onSwitch(type) {
            this.typeOfCalendar = type
            this.setMeetingsType(this.typeOfCalendar)
            if (this.selectedManagers != '') {
                this.selectedManagers = []
            }
        },
        openAdd: function () {
            this.modals.add.isActive = true
        },
        closeAdd: function () {
            this.modals.add.isActive = false
            Object.keys(this.requestData.forClient.fields).forEach(
                (key) => (this.requestData.forClient.fields[key] = '')
            )
        },
        openUpdate: function (meeting) {
            this.selectedMeeting = meeting
            this.modals.update.isActive = true
        },
        closeUpdate: function () {
            this.modals.update.isActive = false
            this.selectedMeeting = null
        },
        onCreate: function ({ date, time }) {
            this.requestData.forClient.fields.date = date
            this.requestData.forClient.fields.time = time
            this.requestData.forClient.fields.calendar =
                this.typeOfCalendar.toUpperCase()
            this.openAdd()
        },
        addHandle: function () {
            if (this.isBusy) return
            let data = {}
            Object.keys(this.requestData.forClient.fields).forEach((field) => {
                if (this.requestData.forClient.fields[field])
                    data = {
                        ...data,
                        [[field]]: this.requestData.forClient.fields[field],
                    }
            })
            this.isBusy = true
            this.onAdd(data)
                .then((res) => {
                    this.success('Meeting has been added')
                    if (this.requestData.forClient.fields.wig_id) {
                        this.updateCompleteDate({
                            id: this.requestData.forClient.fields.wig_id,
                            completeData: this.completeDate,
                        })
                    }
                    this.closeAdd()
                    // this.loadAllV2()
                    return res
                })
                .catch((errors) => {
                    if (!errors) {
                        this.error('something went wrong')
                        this.closeAdd()
                        return
                    }
                    this.requestData.forClient.errors = errors
                })
                .finally(() => (this.isBusy = false))
        },
        onResize() {
            this.windowWidth = window.innerWidth
        },
        printHandle: function () {
            if (
                !this.selectedDay ||
                !this.typeOfCalendar ||
                this.getMeetingPerDay(this.typeOfCalendar, this.selectedDay)
                    .length === 0
            )
                return null

            this.print()
        },
        async print() {
            // Pass the element id here
            await this.$htmlToPaper('print')
        },
        cutterForBtnText: function (text) {
            return text?.split('')?.slice(0, 11)?.join('') || ''
        },
        selectDayHandle: function (date) {
            this.selectedDay = date
        },
        isPickUpNew: function () {
            return this.typeOfCalendar === 'pick_up_new'
        },
        managersHandler: function (managers) {
            this.selectedManagers = managers
        },
        checkIsManager: function () {
            return this.selectedManagers.length !== 0
        },
        changeTypeFormatHandle: function (type) {
            this.typeOfFormat = type
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar {
    &__print-container {
        display: none;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media print {
            display: none;
        }
        button {
            min-width: 100px !important;
        }

        &-container {
            display: flex;
            font-size: 14px;
            flex-wrap: wrap;
            gap: 15px;
        }
    }
}
</style>
